<template>
  <Breadcrumb />
  <Grid
    :settings="gridSettings"
    :columns="gridColumns"
    :rows="fieldList"
    @onEditButtonClick="onEditButtonClick"
  />
</template>
<script>
export default {
  name: "DesignFieldsList",
  components: {},
  data() {
    return {
      gridSettings: {
        action: "DesignFieldsList",
        requestUrl: String.format(""),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [],
        isGatewayRequest: true,

        allowSearchPanel: true,
        allowExcelExport: true,
        allowDragAndDrop: false,
        allowPaging: true,
        allowDeleting: false,
        autoOrderProcess: true,

        buttons: [
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: false,
            emitMethodName: "onEditButtonClick",
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "Fields.CustomObjectName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "customObjectName",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Fields.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Fields.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formulaName",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Fields.FieldType",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "fieldType",
          type: "string",
          visible: true,
          width: 0,
          template: "customObjectFieldTypeTemplate",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Fields.DefaultValue",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "defaultValue",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Fields.Required",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isRequired",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Fields.IsUnique",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isUnique",
          type: "boolean",
          visible: true,
          width: 100,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
      ],
      fieldList: [],
    };
  },
  methods: {
    onEditButtonClick(rowData) {
      let route = this.$router.resolve({
        name: "CustomObjectEditField",
        params: {
          customObjectId: rowData.customObjectPublicId,
          fieldId: rowData.publicId,
        },
      });
      window.open(route.href);
    },
    getFields() {
      this.$prodGatewayAxios
        .get(String.format("/Lcdp-FieldList?customObjectPublicId={0}", ""))
        .then((response) => {
          this.fieldList = response.data.items;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  mounted() {
    this.getFields();
  },
};
</script>
